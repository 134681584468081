<template>
    <div class="sector">
        <div class="sector-head">
            <h1 class="sector-head-title">Grupos</h1>
        </div>

        <div class="form-field-plus">
            <div class="field-plus-legend">Clique para criar um grupo</div>
            <i class="fa fa-long-arrow-right arrow1" aria-hidden="true"></i>
            <div>
                <v-btn fab dark color="secondary" @click="actionCreate()">
                    <v-icon dark> mdi-plus </v-icon>
                </v-btn>
            </div>
        </div>

        <v-alert
            text
            dense
            color="gray"
            icon="mdi-alert-circle-outline"
            border="left"
            v-if="!groups.length">
            Clique no botão abaixo para criar um grupo!
        </v-alert>
        <div class="box-table" v-else>
            <v-data-table
                :headers="headers"
                :items="groups"
                :page.sync="pagination.page"
                :items-per-page="pagination.itemsPerPage"
                hide-default-footer
                class="data-table elevation-2"
                @page-count="pagination.pageCount = $event">
                <template v-slot:[`item.name`]="{ item }">
                    <div class="box-name">
                        <div class="name-item">{{ item.name }}</div>
                    </div>
                </template>

                <template v-slot:[`item.users`]="{ item }">
                    {{ item.users }}
                </template>

                <template v-slot:[`item.managers`]="{ item }">
                    {{ item.managers }}
                </template>

                <template v-slot:[`item.active`]="{ item }">
                    <v-icon :color="getIconActivatedComputed(item.active).color">
                        {{ getIconActivatedComputed(item.active).icon }}
                    </v-icon>
                </template>

                <template v-slot:[`item.created_at`]="{ item }">
                    {{ formatDateComputed(item.created_at) }}
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                    <div v-if="action && item.id === itemIdAllowed" class="confirm">
                        <div>Confirma remoção?</div>

                        <div>
                            <v-btn
                                color="secondary"
                                class="btn-action option confirm-ok"
                                x-small
                                elevation="0"
                                rounded
                                dark
                                @click="actionConfirmDelete(item.id, true)">
                                Sim
                            </v-btn>

                            <v-btn
                                color="red"
                                class="btn-action option"
                                x-small
                                elevation="0"
                                rounded
                                dark
                                @click="actionConfirmDelete(item.id, false)">
                                Não
                            </v-btn>
                        </div>
                    </div>

                    <div v-else>
                        <v-progress-circular
                            :size="20"
                            color="gray"
                            v-show="item.stateActive"
                            indeterminate>
                        </v-progress-circular>

                        <v-btn
                            v-show="!item.stateActive"
                            :title="getConfigButtonAllowedComputed(item.active).title"
                            class="btn-action"
                            x-small
                            elevation="1"
                            rounded
                            plain
                            :disabled="item.stateActive"
                            :color="getConfigButtonAllowedComputed(item.active).color"
                            @click="actionToggleStatusActive(item.id)">
                            {{ getConfigButtonAllowedComputed(item.active).title }}
                        </v-btn>

                        <v-btn @click="actionEdit(item.id)" icon title="Editar">
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>

                        <v-btn icon title="Remover" @click="actionRemove(item.id)">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </div>
                </template>
            </v-data-table>
            <div class="text-center pt-2">
                <v-pagination
                    v-model="pagination.page"
                    :length="pagination.pageCount"></v-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import moment from 'moment';
    import groupsMock from '@/modules/company/mocks/groups.company.mock';
    import { isAmbientGeralTests } from '@/configs/global.config';

    export default {
        name: 'ListGroup',
        data() {
            return {
                isAmbientLocalTests: false,
                pagination: {
                    page: 1,
                    pageCount: 0,
                    itemsPerPage: 10
                },
                action: null,
                itemIdAllowed: null,
                headers: [
                    { text: 'Nome', value: 'name' },
                    { text: 'Alunos', value: 'users', align: 'center' },
                    { text: 'Profissionais', value: 'managers', align: 'center' },
                    { text: 'Dt. criação', value: 'created_at', align: 'center' },
                    { text: 'Ativo?', value: 'active', align: 'center' },
                    { text: 'Ações', value: 'actions', align: 'center', sortable: false }
                ],
                groups: []
            };
        },
        mounted() {
            this.geralLoading();
        },
        computed: {
            ...mapGetters({
                user: 'getDataUser'
            }),
            getIconActivatedComputed() {
                return (active) => {
                    return {
                        icon: 'mdi-check-circle',
                        color: active ? 'green' : '#d0d0d0'
                    };
                };
            },
            getConfigButtonAllowedComputed() {
                return (active) => {
                    return {
                        color: active ? 'red' : 'green',
                        title: active ? 'Desativar' : 'Ativar'
                    };
                };
            },
            formatDateComputed() {
                return (date) => {
                    return moment(date).format('DD/MM/YYYY HH:mm');
                };
            },
            getColorComputed() {
                return (active) => (active ? 'green' : 'red');
            }
        },
        methods: {
            isAmbientTests() {
                return isAmbientGeralTests && this.isAmbientLocalTests;
            },
            geralLoading() {
                if (this.isAmbientTests()) {
                    this.groups = groupsMock.groups;
                } else {
                    this.serviceLoadingGroups();
                }
            },
            actionCreate() {
                this.$store.dispatch('setGroupEditionMode', false);
                this.$store.dispatch('setGroupId', null);
                this.$router.push({ name: 'groupFormCompany' });
            },
            actionEdit(groupId) {
                this.$store.dispatch('setGroupEditionMode', true);
                this.$store.dispatch('setGroupId', groupId);
                this.$router.push({ name: 'groupFormCompany' });
            },
            actionRemove(group) {
                this.action = true;
                this.itemIdAllowed = group;
            },
            mockActionRemove(groupId) {
                this.groups = this.groups.filter((g) => g.id !== groupId);
            },
            efectiveActionDelete(groupId) {
                this.$store.commit('setGlobalLoading', true);

                this.$service.http['company']
                    .deleteGroup(groupId)
                    .then((_) => {
                        this.serviceLoadingGroups();
                    })
                    .catch((e) => {
                        console.error(e);
                    })
                    .finally((_) => {
                        this.$store.commit('setGlobalLoading', false);
                    });
            },
            actionConfirmDelete(groupId, confirm) {
                if (confirm) {
                    if (this.isAmbientTests()) {
                        this.mockActionRemove(groupId);
                    } else {
                        this.efectiveActionDelete(groupId);
                    }
                }

                this.action = false;
                this.itemIdAllowed = null;
            },
            async serviceToggleStatusActive(groupId) {
                await this.$service.http['company']
                    .toggleStatusActiveGroup(groupId)
                    .then((_) => null)
                    .catch((e) => {
                        console.error(e);
                    });
            },
            async actionToggleStatusActive(groupId) {
                const groupIndex = this.groups.findIndex((e) => e.id === groupId);

                if (groupIndex !== -1) {
                    this.groups[groupIndex].stateActive = true;

                    await this.serviceToggleStatusActive(groupId);

                    await new Promise((resolve) => setTimeout(resolve, 2000));

                    this.groups[groupIndex].active = !this.groups[groupIndex].active;

                    this.groups[groupIndex].stateActive = false;
                }
            },
            dataParse(data) {
                const groups = [];

                data.forEach((e) => {
                    groups.push({
                        id: e.id,
                        name: e.name,
                        users: e.users.filter((u) => u.role === 'USER').length,
                        managers: e.users.filter((m) => m.role === 'MANAGER').length,
                        active: e.active,
                        created_at: e.created_at
                    });
                });

                return groups;
            },
            serviceLoadingGroups() {
                this.$store.commit('setGlobalLoading', true);

                const userId = this.user.id;

                this.$service.http['company']
                    .getListGroups(userId)
                    .then((result) => {
                        const records = result.data.docs;

                        this.groups = this.dataParse(records);

                        this.groups.forEach((_, i) => {
                            this.groups[i].index = i + 1;

                            this.$set(this.groups[i], 'stateActive', false);
                        });
                    })
                    .catch((e) => {
                        console.error(e);
                    })
                    .finally((_) => {
                        this.$store.commit('setGlobalLoading', false);
                    });
            }
        }
    };
</script>

<style>
    .confirm-ok {
        margin-right: 10px;
    }

    .btn-number {
        font-size: 20px !important;
        font-weight: bold !important;
        letter-spacing: 0em;
        color: #000000 !important;
    }

    .btn-action {
        text-transform: none !important;
        font-weight: bold;
        width: 70px;
        letter-spacing: 0em;
        margin-right: 10px;
    }

    .option {
        width: 30px !important;
    }

    .box-name {
        margin: 10px;
        letter-spacing: -0.02em;
    }

    .btn-item {
        margin-top: 5px;
    }

    .btn-manager {
        text-transform: none !important;
        font-weight: bold;
        letter-spacing: 0em;
    }

    .form-field-plus {
        display: flex;
        position: fixed;
        align-items: center;
        right: 15px;
        bottom: 60px;
        z-index: 1000;
    }

    .field-plus-legend {
        font-size: 14px;
        color: #777777;
    }

    @keyframes slide1 {
        0%,
        100% {
            transform: translate(0, 0);
        }

        50% {
            transform: translate(10px, 0);
        }
    }

    .arrow1 {
        animation: slide1 1s ease-in-out infinite;
        margin-left: 10px;
        margin-right: 20px;
    }

    .sector-head {
        margin-bottom: 15px;
    }

    .v-data-table-header {
        background-color: #d0d0d0;
    }
</style>
