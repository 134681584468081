export default {
    groups: [
        {
            id: 1,
            name: 'Grupo do terceiro ano',
            managers: 3,
            users: 100,
            created_at: new Date(),
            allowed: true
        },
        {
            id: 2,
            name: 'Grupo ensino medio',
            managers: 1,
            users: 3,
            created_at: new Date(),
            allowed: false
        }
    ]
};
